import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7360bcd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-container p-base justify-space-between align-center" }
const _hoisted_2 = { class: "title line-overflow t-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_icon, {
      icon: $setup.chevronBack,
      onClick: $setup.backRouter
    }, null, 8, ["icon", "onClick"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString($props.title), 1),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.subTitleHandel && $setup.subTitleHandel(...args)))
    }, _toDisplayString($props.subTitle), 1)
  ]))
}