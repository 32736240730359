
import { IonIcon } from "@ionic/vue";
import { chevronBack } from "ionicons/icons";
import router from "@/router";

export default {
  name: "Header",
  components: { IonIcon },
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
  },
  emits: ['subTitleHandel'],
  setup(props: any, context: any) {
    const backRouter = () => {
      router.back();
    };
    const subTitleHandel = () => {
      context.emit('subTitleHandel', {});
    }
    return { chevronBack, subTitleHandel, backRouter };
  },
};
