
import { IonPage, IonContent } from "@ionic/vue";
import { ref } from "vue";
import { skipRouter } from "@/router";
import Header from "@/components/header.vue";

export default {
  name: "SelectedIdentity",
  components: { IonContent, IonPage, Header },
  setup() {
    const isRead = ref(false);
    return { isRead, skipRouter };
  },
};
